let config = {}
if(process.env.NODE_ENV == "development"){
    config = {
        backendurl : "http://localhost:4000/admin/",
    }
}else{
    //=====Live=========//
    // config = {
    //     backendurl : "https://api.banicoin.io/admin/"
    // }
    //=====Staging=========//
    config = {
        backendurl : "https://staging.api.banicoin.io/admin/"
    }
}

export default config